import React, { useContext, useEffect, useRef } from 'react';

const OnboardingLayout = ({ children }) => {

    return (
        <React.Fragment>
            {children}
        </React.Fragment>
    );
};

export default OnboardingLayout;
