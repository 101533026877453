const menuItems = {
    items: [
        {
            id: 'navigation',
            title: 'Navigation',
            type: 'group',
            icon: 'icon-navigation',
            children: [
                {
                    id: 'dashboard',
                    title: 'Dashboard',
                    type: 'item',
                    url: '/mentor/dashboard',
                    icon: 'feather icon-home'
                }
            ]
        },
        {
            id: 'management',
            title: 'Class Management',
            type: 'group',
            icon: 'icon-ui',
            children: [
                {
                    id: 'addClass',
                    title: 'Add Class',
                    type: 'item',
                    url: '/mentor/class/add',
                    icon: 'feather icon-plus-square'
                },
                {
                    id: 'classList',
                    title: 'My Classes',
                    type: 'item',
                    url: '/mentor/class/list',
                    icon: 'feather icon-server'
                }
            ]
        },      
    ]
};

export default menuItems;
