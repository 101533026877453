const menuItems = {
    items: [
        {
            id: 'navigation',
            title: 'Navigation',
            type: 'group',
            icon: 'icon-navigation',
            children: [
                {
                    id: 'dashboard',
                    title: 'Dashboard',
                    type: 'item',
                    url: '/admin/dashboard',
                    icon: 'feather icon-home'
                }
            ]
        },
        {
            id: 'user',
            title: 'User Management',
            type: 'group',
            icon: 'icon-ui',
            children: [
                {
                    id: 'mentors',
                    title: 'Mentors',
                    type: 'collapse',
                    icon: 'feather icon-check-circle',
                    children: [
                        {
                            id: 'add',
                            title: 'Add Mentor',
                            type: 'item',
                            url: '/admin/mentor/add'
                        },
                        {
                            id: 'list',
                            title: 'My Mentors',
                            type: 'item',
                            url: '/admin/mentor/all'
                        },
                    ]
                },
                {
                    id: 'learner',
                    title: 'Learners',
                    type: 'item',
                    url: '/admin/learner',
                    icon: 'feather icon-check-circle'
                }
            ]
        },     
    ]
};

export default menuItems;
